.hero-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  position: relative;
  /* background: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)),
    url("../../assets/img/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  /* position: relative; */
}
.slideshow-cont {
  width: 100%;
  height: max-content;
  position: relative;
}
.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Adjust the opacity as needed */
  z-index: 1;
}
.each-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

/** For the video cont header text */
.heroVid-HeadCont {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  z-index: 2;
}

.heroVid-HeadCont h1 {
  font-size: 5rem;
}
.heroVid-HeadCont p {
  font-size: 1.8rem;
  width: 80%;
  line-height: 45px;
}

/** End of the styling of vid cont header  */

.textImg-display {
  position: absolute;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  bottom: 40px;
  right: 20px;
  z-index: 2;
}

.hero-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: #fff;
  z-index: 2;
}
.hero-header > h1 {
  font-size: 5rem;
}
.hero-header > p {
  font-size: 2rem;
}
.blue-head {
  color: #019cdf;
}
.insta-icon {
  background: #833ab4;
  background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
}
.red-head {
  color: #ea1d24;
}
.hero-social {
  color: #fff;
  display: flex;
  gap: 15px;
  font-size: 2.4rem;
  z-index: 2;
}
.hero-btn {
  z-index: 2;
  margin-top: 40px;
}
.btn-hero {
  background: #019cdf;
  padding: 15px;
  border-radius: 5px;
}
/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .hero-header > h1 {
    font-size: 3rem;
    text-align: center;
  }
  .hero-header > p {
    font-size: 1.5rem;
    text-align: center;
  }
  .each-slide video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }
  .heroVid-HeadCont h1 {
    font-size: 3rem;
  }
  .heroVid-HeadCont p {
    font-size: 1.5rem;
  }
}

/** For medium device */
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 576px) {
  .textImg-display {
    position: absolute;
    background: #fff;
    padding: 10px;
    bottom: 10px;
    right: 10px;
  }
  .hero-cont {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.1)
      ),
      linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)),
      url("../../assets/img/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .hero-header > h1 {
    font-size: 2rem;
    text-align: center;
  }
  .hero-header > p {
    font-size: 1.2rem;
    text-align: center;
  }
  .heroVid-HeadCont h1 {
    font-size: 2rem;
    width: 88%;
  }
  .heroVid-HeadCont p {
    font-size: 1.2rem;
    line-height: normal;
  }
}

/** For small devices */
@media screen and (max-width: 350px) {
  .hero-header > h1 {
    font-size: 1.4rem;
    text-align: center;
  }
  .hero-header > p {
    font-size: 1rem;
    text-align: center;
  }
  .heroVid-HeadCont h1 {
    font-size: 1.4rem;
  }
  .heroVid-HeadCont p {
    font-size: 1rem;
  }
}
