.coupon-modalMainCont {
  position: fixed;
  top: initial;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
}
.coupon-modal {
  display: flex;
  background: var(--blue-color);
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  padding: 100px 20px;
  position: relative;
}
.closeModalButton {
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 20px;
  right: 20px;
}
.coupon-modalDetail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
}
.coupon-modalDetail a {
  text-decoration: underline;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .coupon-modal {
    padding: 50px 20px;
  }
  .coupon-modalDetail h1 {
    font-size: 1.5rem;
    line-break: loose;
  }
  .closeModalButton {
    color: #fff;
    font-size: 2rem;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .coupon-modal {
    padding: 50px 20px;
  }
  .coupon-modalDetail h1 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 320px) {
  .coupon-modal {
    padding: 50px 10px;
  }
  .coupon-modalDetail h1 {
    font-size: 1.1rem;
  }
}
