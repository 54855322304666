.ukvi-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  padding: 40px 0;
}
.ukvi-header {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 400;
}
.ukvi-detailCont {
  display: flex;
  gap: 30px;
}
.ukvi-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  height: max-content;
  width: 300px;
  border: 1px solid #000;
  z-index: 999;
  padding: 80px 20px;
  color: #000;
}
.ukvi-btn {
  background: var(--blue-color);
  text-align: center;
  padding: 10px 12px;
  border-radius: 5px;
}

/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .ukvi-cont {
    padding-bottom: 130px;
  }
  .ukvi-detailCont {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/** For medium device */
@media screen and (max-width: 576px) {
  .ukvi-header {
    font-size: 1.8rem;
  }
}

/** For small devices */
@media screen and (max-width: 360px) {
  .ukvi-header {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 320px) {
  .ukvi-header {
    font-size: 1.4rem;
  }
}
