.service-cont {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 40px;
  gap: 60px;
}
.service-header {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 400;
}
.service-paraCont {
  display: flex;
  align-items: center;
  gap: 100px;
}
.paraCont {
  width: 495px;
  height: max-content;
  border: 1px solid #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
}
.paraCont > img {
  width: 100px;
}
.paraCont > h3 {
  font-size: 1.5rem;
}
.para-btn {
  margin-top: 10px;
  background: var(--blue-color);
  padding: 15px;
  border-radius: 5px;
}

/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .service-cont {
    padding-bottom: 150px;
  }
  .service-paraCont {
    gap: 50px;
    flex-direction: column;
  }
  .paraCont {
    width: 400px;
  }
}

/** For medium device */
@media screen and (max-width: 576px) {
  .service-header {
    font-size: 1.8rem;
  }
  .service-paraCont {
    gap: 50px;
  }
  .paraCont {
    width: 350px;
  }
  .paraCont > img {
    width: 80px;
  }
  .paraCont > h3 {
    font-size: 1.2rem;
  }
  .paraCont > p {
    font-size: 0.9rem;
  }
}

/** For small devices */
@media screen and (max-width: 360px) {
  .paraCont {
    width: 320px;
  }
  .service-header {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 320px) {
  .paraCont {
    width: 280px;
  }
  .service-header {
    font-size: 1.4rem;
  }
}
