.generalTestInfo-detailCont {
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 20px;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  border-top: 1px solid var(--red-color);
  border-bottom: 1px solid var(--red-color);
  background: #eeee;
  padding-top: 30px;
}
.generalTestInfo-detailCont hr {
  height: 100px;
}
.generalTestInfo-detailItem h3 {
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 10px;
}
.generalTestInfo-detailItem p {
  font-size: 1.6rem;
  font-weight: bold;
}
.generalTestInfo-detailCont a {
  background: var(--blue-color);
  padding: 12px;
  width: max-content;
  height: max-content;
  border-radius: 5px;
}
/* * For medium device */
@media screen and (max-width: 576px) {
  .generalTestInfo-detailCont {
    flex-direction: column;
    height: max-content;
    justify-content: center;
    padding: 40px;
  }
  .generalTestInfo-detailCont hr {
    height: 0;
    width: 380px;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .generalTestInfo-detailCont hr {
    width: 320px;
  }
}
@media screen and (max-width: 320px) {
  .generalTestInfo-detailCont hr {
    width: 307px;
  }
}
