.main-blogBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 200px;
  gap: 40px;
}
/* //*For single blog header */
.singleBlog-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.singleBlog-head h1 {
  font-size: 3rem;
  text-align: center;
}
.singleBlog-head p {
  color: grey;
  font-weight: bold;
  font-size: 1.2rem;
}

/* //*blog image and author name */
.single-blogimgCont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.single-blogimgCont > img {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.single-blogimgCont > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.single-blogimgCont > div img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
}
/* //* The main Blog body  */
.single-blogDetailCont {
  display: flex;
  gap: 100px;
  flex-direction: column-reverse;
  padding: 0 100px;
}
.single-sociShareCont {
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  color: var(--blue-color);
}
.single-blogSocial {
  display: inline-flex;
  gap: 20px;
}
.single-blogSocial a,
.single-blogSocial div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #000;
  display: inline-flex;
  align-items: center;
  color: #000;
  justify-content: center;
  cursor: pointer;
}
/* //* For the blog detailed body  */
.single-blogMainDetailCont {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.single-blogMainDetailCont ul {
  list-style-type: disc;
  margin-top: 10px;
  font-size: 1.2rem;
  margin-left: 10px;
  padding-left: 10px;
}

.single-blogMainDetailCont h1 {
  font-size: 2.5rem;
  font-weight: bolder;
}
.single-blogMainDetailCont p {
  font-size: 1.3rem;
  line-height: 35px;
}
.single-blogMainDetailCont h2 {
  font-style: italic;
  font-weight: bold;
  color: grey;
  line-height: 34px;
  font-size: 1.5rem;
  border-left: 3px solid var(--blue-color);
  padding-left: 20px;
}
.single-blogMainDetailCont h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .main-blogBody {
    padding: 10px 50px 80px;
  }
  .single-blogimgCont > img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .single-blogDetailCont {
    padding: 0 80px;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .main-blogBody {
    padding: 10px 20px 80px;
  }
  .single-blogimgCont > img {
    width: 100%;
    height: 250px;
  }
  .single-blogDetailCont {
    padding: 0 20px;
  }
  .singleBlog-head h1 {
    font-size: 2rem;
  }
  .single-blogMainDetailCont h1 {
    text-align: center;
    font-size: 1.8rem;
  }
}
