.whatsapp {
  position: fixed;
  z-index: 999;
  bottom: 65px;
  right: 170px;
  color: #2cb04f;
  font-size: 4rem;
}
.whatsapp-deskCont {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 10px;
  position: fixed;
  bottom: 75px;
  right: 20px;
  z-index: 900;
  border-radius: 50px;
  padding-left: 40px;
  background: var(--blue-color);
  color: #fff;
  clip-path: polygon(25px 0%, 100% 0%, 100% 100%, 30px 100%, 0% 50%);
}
.whatsapp-deskContMobile {
  display: none;
}
.whatsapp-deskCont span {
  color: var(--red-color);
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .whatsapp {
    bottom: 100px;
    right: 100px;
    font-size: 2.7rem;
    display: block;
  }
  .whatsapp-deskCont {
    display: none;
  }
  .whatsapp-deskContMobile {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 0;
    position: fixed;
    bottom: 90px;
    right: 10px;
    z-index: 900;
    border-radius: 50px;
    padding-left: 15px;
    background: var(--blue-color);
    color: #fff;
    /* clip-path: polygon(25px 0%, 100% 0%, 100% 100%, 30px 100%, 0% 50%); */
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .whatsapp {
    bottom: 122px;
    right: 35px;
    font-size: 2.7rem;
  }
}
