.price-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 110px;
  padding-bottom: 80px;
  gap: 50px;
  padding-bottom: 500px;
  position: relative;
  /* height: 0; */
  max-width: 100%;
  margin-top: 80px;
  margin-bottom: 300px;
}

.coursePrice-btn {
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 10px 20px;
  color: #fff;
  background-color: var(--blue-color);
  text-align: center;
}
.price-Btn {
  background: var(--blue-color);
  width: 230px;
  padding: 10px;
  border-radius: 5px;
}
.priceBtn-cont {
  position: absolute;
  left: 500px;
  top: 20px;
  display: flex;
  align-items: center;
  gap: 30px;
}
.iframe {
  position: absolute;
  top: 100px;
  border: none;
  width: 100%;
  height: 100vh;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .priceBtn-cont {
    left: 200px;
  }
}
@media screen and (max-width: 768px) {
  .priceBtn-cont {
    left: 150px;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .price-Btn {
    font-size: 0.8rem;
  }
  .priceBtn-cont {
    position: absolute;
    flex-direction: column;
    left: 100px;
    top: -20px;
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .priceBtn-cont {
    position: absolute;
    flex-direction: column;
    left: 70px;
    top: -20px;
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
@media screen and (max-width: 320px) {
  .priceBtn-cont {
    position: absolute;
    flex-direction: column;
    left: 50px;
    top: -20px;
    display: flex;
    align-items: center;
    gap: 30px;
  }
}
