.blog-MainCont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 150px 100px;
}
.blog-head {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.blog-head h1 {
  font-size: 2.5rem;
}
.blog-head p {
  font-size: 1.3rem;
  font-weight: 400;
  color: #333;
}
.blog-detailCont {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}
.single-blogCont {
  width: 360px;
  height: max-content;
  display: flex;
  gap: 20px;
  overflow: hidden;
  flex-direction: column;
  padding-bottom: 10px;
}
.single-blogCont img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.small-blogDetail {
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
}

.small-blogDetail h3 {
  color: lightslategray;
}
.small-blogDetailPara {
  color: #333;
  /* height: 60px; */
  overflow: hidden;
  display: -webkit-box;
  line-height: 1.25rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.blog-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.blog-date p {
  font-size: 0.8rem;
  color: lightslategray;
}
.blog-btn {
  width: 100px;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  background: var(--blue-color);
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .blog-MainCont {
    padding: 20px 35px 70px;
  }
  .blog-head h1 {
    font-size: 2rem;
  }
  .blog-head {
    align-items: center;
  }
  .blog-detailCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .blog-MainCont {
    padding: 20px 28px 70px;
  }
  .blog-head h1 {
    font-size: 2rem;
  }
  .blog-head {
    align-items: center;
  }
  .blog-detailCont {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .single-blogCont {
    width: 310px;
  }
}
@media screen and (max-width: 320px) {
  .blog-MainCont {
    padding: 20px 20px 70px;
  }
  .single-blogCont {
    width: 280px;
  }
  .small-blogDetail h2 {
    font-size: 1.3rem;
  }

  .small-blogDetail > p {
    font-size: 0.9rem;
  }
}
