.modalBg {
  display: none;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .modalBg {
    position: fixed;
    top: initial;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
  }
  .modalCont {
    width: 400px;
    height: max-content;
    padding: 50px 20px;
    border-radius: 12px;
    background-color: #1e1e1e;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .modalCont > div {
    color: #fff;
    font-size: 2rem;
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .modalCont ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
  .modalCont ul li {
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .modalCont {
    width: 350px;
  }

  .modalCont ul li {
    font-size: 1.2rem;
  }
}

/* * For small devices */
@media screen and (max-width: 360px) {
  .modalCont {
    width: 320px;
  }
}
@media screen and (max-width: 320px) {
  .modalCont {
    width: 290px;
  }
}
