.dropDownPriceList-cont {
  position: absolute;
  top: 80px;
  height: max-content;
}

.dropDownPriceList-contItem {
  display: flex;
  flex-direction: column;
  background-color: #000;
  height: 100%;
}

.dropDownPriceList-contItem li {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  height: 50px;
}
.dropDownPriceList-contItem li:hover {
  background-color: #333;
  border: 1px solid #fff;
}
