.selectLang {
  width: 125px;
}
/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .selectLang {
    width: 125px;
    display: none;
  }
}
