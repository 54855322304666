.ukvi-generalCont {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  padding-top: 120px;
  padding-bottom: 80px;
}

/* //* for coupon code box styling */

.ukvi-couponCont {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.coupon {
  padding: 13px 40px;
  background-color: var(--red-color);
  color: #fff;
  width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  border-radius: 10px;
}
.coupon button {
  background: var(--blue-color);
  color: #fff;
  font-size: 1rem;
  outline: none;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.coupon span {
  font-size: 1.2rem;
  font-weight: bold;
}

.ukvi-faqCont {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
.ukvi-faqCont > h2 {
  font-size: 2rem;
  align-self: self-start;
  padding-left: 100px;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .ukvi-testCont {
    flex-wrap: wrap;
  }
  .ukvi-generalCont {
    padding-top: 40px;
  }
  .ukvi-faqCont > h2 {
    padding-left: 50px;
  }
  .coupon {
    gap: 30px;
    flex-direction: column;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .ukvi-faqCont > h2 {
    padding-left: 25px;
    font-size: 1.7rem;
  }
  .ukvi-couponCont {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .coupon {
    gap: 30px;
    flex-direction: column;
  }
}

/* * For small devices */
@media screen and (max-width: 320px) {
  .coupon {
    padding: 13px 20px;
    background-color: var(--red-color);
    color: #fff;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 360px) {
  .ukvi-faqCont > h2 {
    font-size: 1.5rem;
  }
  .priceCont {
    gap: 70px;
  }
}
