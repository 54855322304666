/** For the navbar font */
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&display=swap");
/** For general body Font  */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --nav-bar-font: "Merriweather Sans", sans-serif;
  --general-font: "Roboto", sans-serif;
  --red-color: #ea1d24;
  --blue-color: #019cdf;
  --nav-bgColor: #1e1e1e;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: var(--general-font);
}
a {
  color: #fff;
  font-family: var(--nav-bar-font);
}

html {
  scroll-behavior: smooth;
}
/* 
! BREAKPOINTS

* For large devices
@media screen and (max-width: 992px) {
}

* For medium device
@media screen and (max-width: 576px) {
}

* For small devices
@media screen and (max-width: 390px) {
}
@media screen and (max-width: 320px) {
} */
