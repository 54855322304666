.offer-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.offer-detail {
  display: flex;
  gap: 80px;
}
.detail-cont {
  width: 400px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  /* border: 1px solid black; */
  padding: 30px 10px;
}
.detail-cont > img {
  width: 120px;
}
.detail-cont > h3 {
  font-size: 1.5rem;
}
.teach {
  height: 115px;
}
.offer-btn {
  background: var(--blue-color);
  padding: 10px 40px;
  text-align: center;
  border-radius: 50px;
  margin-top: 40px;
}
/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .offer-cont {
    height: max-content;
    padding-top: 30px;
    padding-bottom: 150px;
  }
  .offer-detail {
    flex-direction: column;
  }
}

/** For medium device */
@media screen and (max-width: 576px) {
  .offer-detail {
    gap: 20px;
  }
  .detail-cont {
    width: 330px;
  }
}

/** For small devices */
@media screen and (max-width: 375px) {
}

@media screen and (max-width: 320px) {
  .detail-cont {
    width: 300px;
  }
}
