.bannerHeaderCont {
  display: flex;
  position: sticky;
  top: 80px;
  left: 0;
  align-items: center;
  justify-content: space-evenly;
  height: 99px;
  width: 100%;
  background: #414c55;
  color: #fff;

  z-index: 900;
}

.bannerHeader-txtCont {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 1.7rem;
}
.bannerHeader-couponCont {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.bannerHeader-couponCont button {
  background-color: var(--blue-color);
  color: #fff;
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1.3rem;
  outline: none;
  border: none;
}
.bannerHeader-closeBtn {
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}
.bannerHeader-closeBtn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .bannerHeaderCont {
    top: 0;
    position: fixed;
  }
  .bannerHeader-txtCont {
    font-size: 1.1rem;
    gap: 5px;
  }
  .bannerHeader-couponCont button {
    font-size: 1.12rem;
  }
  .bannerHeader-closeBtn {
    width: 30px;
    height: 30px;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .bannerHeaderCont {
    height: max-content;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .bannerHeader-closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .bannerHeader-txtCont {
    font-size: 1rem;
    margin-top: 13px;
  }
  .bannerHeader-closeBtn {
    width: 25px;
    height: 25px;
  }
}

/* * For small devices */
@media screen and (max-width: 397px) {
  .bannerHeader-txtCont {
    font-size: 0.85rem;
  }
  .bannerHeader-couponCont button {
    font-size: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .bannerHeader-txtCont {
    font-size: 0.7rem;
  }
  .bannerHeader-couponCont h3 {
    font-size: 0.9rem;
  }
}
