.test-centreCont {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: max-content;
}
.test-centreHeroCont {
  display: flex;
  width: 100%;
  height: 100vh;
}
.test-centreHeroText {
  width: 50%;
  display: flex;
  padding: 50px;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.test-centreHeroText h1 {
  font-size: 2.2rem;
  width: 70%;
  font-weight: 700;
}
.test-centreHeroText p {
  font-size: 1.5rem;
  width: 80%;
}

/* //* This is for the div container side image style */
.test-centreHeroImg {
  width: 50%;
  height: 100%;
  background-image: url("../../assets/img/test-centre-pic.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* //* For the logo carousel movement and styling */
.carousel {
  overflow: hidden;
  white-space: nowrap;
  width: 70%;
}
.carousel-inner {
  display: inline-flex;
  align-items: center;
  animation: flow 20s linear infinite;
}
.carousel-item {
  display: inline-block;
  margin-right: 100px;
}
.carousel-item img {
  width: 300px;
  object-fit: fit;
}
@keyframes flow {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .test-centreHeroCont {
    flex-direction: column;
  }
  .test-centreHeroText {
    padding: 0;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 60%;
  }
  .test-centreHeroText h1 {
    width: 80%;
  }
  .test-centreHeroImg {
    width: 100%;
    height: 50%;
  }
  /* //* For the logo carousel movement and styling */
  .carousel {
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    margin-top: 20px;
  }
  .carousel-inner {
    display: inline-flex;
    align-items: center;
    animation: flow 30s linear infinite;
  }
  .carousel-item {
    display: inline-block;
    margin-right: 100px;
  }
  .carousel-item img {
    width: 280px;
    object-fit: fit;
  }
  @keyframes flow {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .test-centreHeroCont {
    flex-direction: column;
  }
  .test-centreHeroText {
    padding: 0;
    align-items: center;
    text-align: center;
    gap: 30px;
    width: 100%;
    height: 70%;
    padding: 50px 0;
  }
  .test-centreHeroText h1 {
    width: 80%;
    font-size: 1.7rem;
  }
  .test-centreHeroText p {
    font-size: 1rem;
    width: 90%;
  }
  .test-centreHeroImg {
    width: 100%;
    height: 60%;
  }
  /* //* For the logo carousel movement and styling */
  .carousel {
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    margin-top: 20px;
  }
  .carousel-inner {
    display: inline-flex;
    align-items: center;
    animation: flow 30s linear infinite;
  }
  .carousel-item {
    display: inline-block;
    margin-right: 100px;
  }
  .carousel-item img {
    width: 280px;
    object-fit: fit;
  }
  @keyframes flow {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .test-centreHeroCont {
    flex-direction: column;
  }
  .test-centreHeroText {
    padding: 0;
    align-items: center;
    text-align: center;
    gap: 30px;
    width: 100%;
    height: 70%;
    padding: 50px 0;
  }
  .test-centreHeroText h1 {
    width: 90%;
    font-size: 1.3rem;
  }
  .test-centreHeroText p {
    font-size: 0.9rem;
    width: 90%;
  }
  .test-centreHeroImg {
    width: 100%;
    height: 60%;
  }
  /* //* For the logo carousel movement and styling */
  .carousel {
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    margin-top: 20px;
  }
  .carousel-inner {
    display: inline-flex;
    align-items: center;
    animation: flow 30s linear infinite;
  }
  .carousel-item {
    display: inline-block;
    margin-right: 100px;
  }
  .carousel-item img {
    width: 200px;
    object-fit: fit;
  }
  @keyframes flow {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
@media screen and (max-width: 320px) {
  .test-centreHeroText {
    padding: 0;
    align-items: center;
    text-align: center;
    gap: 20px;
    width: 100%;
    height: 600px;
    padding: 50px 0;
  }
  .test-centreHeroCont {
    height: max-content;
  }
  .test-centreHeroText h1 {
    width: 90%;
    font-size: 1.3rem;
  }
  .test-centreHeroText p {
    font-size: 0.9rem;
    width: 90%;
  }
  .test-centreHeroImg {
    width: 100%;
    height: 400px;
  }
}
