.downloadablePage-mainCont {
  width: 100%;
  height: max-content;
}
.downloadablePage-detailCont {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #000000,
    #444444,
    #888888,
    #444444,
    #000000
  );
  gap: 10rem;
  color: #fff;
}

.downloadablePage-detailCont img {
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.downloadablePage-detailCont-text {
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.downloadablePage-detailCont-text h1 {
  font-size: 3.5rem;
}

.downloadablePage-detailCont-text p {
  font-size: 2rem;
  width: 550px;
}
.downloadablePage-pdfCont {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 150px 100px;
  width: 100%;
  flex-wrap: wrap;
  background: #e1dddd;
}

.downloadablePage-pdfItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.downloadablePage-pdfItem img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.downloadablePage-pdfItem h3 {
  font-size: 1.5rem;
}
.downloadablePage-pdfItem a {
  color: #000000;
  background: var(--blue-color);
  border-radius: 5px;
  color: #fff;
  padding: 12px;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .downloadablePage-detailCont {
    flex-direction: column;
    padding: 0px;
    gap: 70px;
  }
  .downloadablePage-detailCont img {
    width: 400px;
    height: 400px;
  }
  .downloadablePage-detailCont-text {
    width: 100%;
    padding: 0 100px;
    align-items: center;
    text-align: center;
  }
  .downloadablePage-detailCont-text h1 {
    font-size: 2.5rem;
  }
  .downloadablePage-detailCont-text p {
    font-size: 1.5rem;
  }
  .downloadablePage-pdfCont {
    padding: 60px 50px;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .downloadablePage-detailCont {
    flex-direction: column;
    padding: 0px;
    gap: 70px;
  }

  .downloadablePage-detailCont img {
    width: 300px;
    height: 300px;
  }
  .downloadablePage-detailCont-text {
    width: 100%;
    padding: 0 40px;
    align-items: center;
    text-align: center;
  }
  .downloadablePage-detailCont-text h1 {
    font-size: 2rem;
  }
  .downloadablePage-detailCont-text p {
    font-size: 1.1rem;
    width: 90%;
  }
  .downloadablePage-pdfCont {
    justify-content: center;
    padding: 60px 0px;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
}
@media screen and (max-width: 320px) {
  .downloadablePage-detailCont {
    flex-direction: column;
    padding: 320px 20px;
    gap: 40px;
  }

  .downloadablePage-detailCont img {
    width: 280px;
    height: 280px;
  }
  .downloadablePage-detailCont-text {
    width: 100%;
    padding: 0 10px;
    align-items: center;
    text-align: center;
  }
  .downloadablePage-detailCont-text h1 {
    font-size: 1.5rem;
  }
  .downloadablePage-detailCont-text p {
    font-size: 1rem;
    width: 90%;
  }
  .downloadablePage-pdfCont {
    justify-content: center;
    padding: 60px 0px;
  }
  .downloadablePage-pdfItem img {
    width: 270px;
    height: 270px;
    object-fit: cover;
  }

  .downloadablePage-pdfItem h3 {
    font-size: 1.3rem;
  }
  .downloadablePage-pdfItem a {
    color: #000000;
    font-size: 0.9rem;
    background: var(--blue-color);
    border-radius: 5px;
    color: #fff;
    padding: 10px;
  }
}
