.more-infoMainCont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 40px;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .more-infoMainCont {
    flex-direction: column;
    padding: 200px 0;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .more-infoMainCont {
    padding: 650px 0;
  }
}
