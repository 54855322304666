.bannerBody-mainCont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  padding: 50px 0;
  background-color: #414c55;
}

.bannerBody-cont {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  gap: 30px;
  background: var(--blue-color);
  width: 500px;
  height: 500px;
  color: #fff;
  border-radius: 10px;
}
.bannerBody-cont h1 {
  font-size: 2.8rem;
}
.bannerBody-cont h1 span {
  color: var(--red-color);
}
.bannerBody-cont p {
  font-size: 1.2rem;
}

.bannerBody-btnCont {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 40px;
  width: max-content;
  cursor: pointer;
  gap: 40px;
  padding: 0 20px;
  background: var(--red-color);
  color: #fff;
}
.bannerBody-btnCont p {
  font-size: 1.2rem;
}
.bannerBody-btnCont button {
  padding: 0 10px;
  display: flex;
  cursor: pointer;
  height: 100%;
  margin-right: -20px;
  font-size: 1.2rem;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  align-items: center;
  gap: 5px;
}
.bannerBody-linkBtnCont {
  display: flex;
  align-items: center;
  gap: 20px;
}
.bannerBody-linkBtnCont a,
.bannerBody-link {
  color: #fff;
  background: var(--red-color);
  padding: 10px;
  border-radius: 10px;
}

.bannerBody-linkBtnCont a:hover,
.bannerBody-link:hover {
  color: var(--red-color);
  background: #fff;
  border: 2px solid var(--red-color);
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .bannerBody-cont {
    width: 335px;
    height: max-content;
    padding: 30px 20px;
  }
  .bannerBody-cont h1 {
    font-size: 1.8rem;
  }
  .bannerBody-cont p {
    font-size: 1rem;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
}
@media screen and (max-width: 320px) {
  .bannerBody-cont {
    width: 300px;
    height: max-content;
    padding: 30px 20px;
  }
  .bannerBody-cont h1 {
    font-size: 1.5rem;
  }
  .bannerBody-cont p {
    font-size: 0.8rem;
  }
  .bannerBody-linkBtnCont a,
  .bannerBody-link {
    font-size: 0.8rem;
  }
}
