.full-screen-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.popup-content p > span {
  font-weight: bolder;
  color: var(--red-color);
  font-size: 1.3rem;
}
.popup-content img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.popBtnCont {
  display: inline-flex;
  gap: 40px;
}
.messageBtn {
  background-color: var(--blue-color);
  color: white;
  border: none;
  border-radius: 4px;
  width: 120px;
  padding: 10px 5px;
  cursor: pointer;
  margin-top: 20px;
}

.close-button {
  background-color: #ff5c5c;
  color: white;
  border: none;
  border-radius: 4px;
  width: 120px;
  padding: 10px 5px;
  cursor: pointer;
  margin-top: 20px;
}

.close-button:hover {
  background-color: #ff1c1c;
}

/* ! BREAKPOINTS */

/* * For medium device */
@media screen and (max-width: 576px) {
  .popBtnCont {
    gap: 20px;
  }
}
