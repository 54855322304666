.scrolldownDownload_cont {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 180px;
  padding: 8px;
  height: 50px;
  background: var(--red-color);
  color: #fff;
  border-radius: 5px;
  gap: 10px;
}
.scrolldownDownload_cont img {
  width: 30px;
  height: 30px;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(0.2rem);
  }
}

.scrolldownDownloadBtn_name {
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
  margin-left: 2px;
}
.scrolldownDownload_down {
  font-size: 1.25rem;
  color: var(--title-color);
}

/*!=============== BREAKPOINTS ===============*/
/** For large devices */
@media screen and (max-width: 992px) {
  .scrolldownDownload_cont {
    margin-left: 0rem;
  }
}

@media screen and (max-width: 576px) {
  .scrolldownDownload_cont {
    width: 180px;
  }
}

/** For small devices */
@media screen and (max-width: 350px) {
}
