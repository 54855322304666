.academic-detailCont {
  display: flex;
  gap: 30px;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid var(--red-color);
  border-bottom: 1px solid var(--red-color);
  background: #eeee;
}
.academic-detailCont a {
  background: var(--blue-color);
  padding: 12px;
  width: max-content;
  height: max-content;
  border-radius: 5px;
}
.academic-sumCont {
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  gap: 20px;
}
.academic-sumCont h1 {
  font-size: 3rem;
}
.academic-sumCont p {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.7rem;
}
.academic-btnCont {
  padding-top: 40px;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}
.academic-btnCont a,
.academic-btnCont button {
  padding: 15px;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 10px;
  background: var(--blue-color);
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .academic-detailCont {
    flex-direction: column;
    text-align: center;
  }
  .academic-sumCont {
    padding: 0 25px;
  }
  .academic-sumCont h1 {
    font-size: 2rem;
  }
  .academic-sumCont p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2.2rem;
  }
}
