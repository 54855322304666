.comment-cont {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  background: var(--red-color);
  padding: 50px 0;
}
.comment-title {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 400;
  color: #fff;
}

.comment_cont {
  width: 450px;
}

.comment_card {
  background-color: #fff;
  box-shadow: 1px 2px#000;
  padding: 15px 30px;
  border-radius: 1.5rem;
  margin: 30px 0;
}

.comment_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 7px;
}

.comment_name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.comment_btn {
  background-color: var(--blue-color);
  padding: 15px 0;
  text-align: center;
  border-radius: 10px;
  width: 220px;
  align-self: center;
}
.comment {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 576px) {
  .comment_cont {
    width: 340px;
  }
  .comment-title {
    font-size: 1.8rem;
  }
}

/** For small devices */
@media screen and (max-width: 360px) {
  .comment_cont {
    width: 300px;
  }
  .comment-title {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 320px) {
  .comment-title {
    font-size: 1.4rem;
  }
}
