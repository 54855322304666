.learn-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  padding-top: 30px;
  padding-bottom: 40px;
}
.learn-headerCont {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.head {
  font-size: 2.5rem;
  font-weight: 400;
}
.red {
  color: var(--red-color);
}
.line {
  border: 1px solid #000;
  width: 1200px;
}
/** For the Logo and details */
.logo-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  padding-top: 60px;
  padding-bottom: 100px;
}
.logo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
}
.logo-img {
  font-size: 4rem;
}

/*! BREAKPOINTS */

/** For large devices */
@media screen and (max-width: 992px) {
  .learn-cont {
    padding-bottom: 150px;
  }
  .logo-items {
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .line {
    width: 600px;
  }
}

/** For medium device */
@media screen and (max-width: 576px) {
  .head {
    font-size: 1.8rem;
  }
  .line {
    width: 370px;
  }
  .logo-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
}

/** For small devices */
@media screen and (max-width: 375px) {
  .head {
    font-size: 1.6rem;
  }
  .line {
    width: 350px;
  }
}

@media screen and (max-width: 320px) {
  .head {
    font-size: 1.4rem;
  }
  .line {
    width: 300px;
  }
}
